const Donut = ({ label, offset }) => {
    switch( label ) {
        case 'total':
            return (
                <div className="donut__total">
                    <svg width="275" height="276" viewBox="0 0 275 276" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_972_38248" fill="white">
                    <path d="M275 138C275 213.939 213.439 275.5 137.5 275.5C61.5608 275.5 0 213.939 0 138C0 62.0608 61.5608 0.5 137.5 0.5C213.439 0.5 275 62.0608 275 138ZM44 138C44 189.639 85.8614 231.5 137.5 231.5C189.139 231.5 231 189.639 231 138C231 86.3614 189.139 44.5 137.5 44.5C85.8614 44.5 44 86.3614 44 138Z"/>
                    </mask>
                    <path d="M275 138C275 213.939 213.439 275.5 137.5 275.5C61.5608 275.5 0 213.939 0 138C0 62.0608 61.5608 0.5 137.5 0.5C213.439 0.5 275 62.0608 275 138ZM44 138C44 189.639 85.8614 231.5 137.5 231.5C189.139 231.5 231 189.639 231 138C231 86.3614 189.139 44.5 137.5 44.5C85.8614 44.5 44 86.3614 44 138Z" fill="#DB57B6" stroke="black" strokeWidth="8" mask="url(#path-1-inside-1_972_38248)"/>
                    <mask id="path-2-inside-2_972_38248" fill="white">
                    <path d="M137.5 0.5C170.983 0.5 203.315 12.7173 228.43 34.8597C253.546 57.0022 269.719 87.5481 273.916 120.767C278.112 153.985 270.044 187.594 251.224 215.286C232.404 242.979 204.126 262.853 171.695 271.18C139.264 279.507 104.909 275.714 75.0763 260.513C45.243 245.313 21.9815 219.748 9.65572 188.617C-2.67005 157.486 -3.21293 122.927 8.12891 91.4235C19.4708 59.9203 41.9179 33.6382 71.2589 17.5078L92.4561 56.0653C72.5042 67.034 57.2401 84.9058 49.5277 106.328C41.8152 127.75 42.1844 151.25 50.5659 172.42C58.9474 193.589 74.7653 210.973 95.0519 221.309C115.338 231.646 138.7 234.225 160.752 228.563C182.805 222.9 202.034 209.386 214.832 190.555C227.63 171.724 233.116 148.87 230.263 126.281C227.409 103.693 216.411 82.9215 199.333 67.8646C182.254 52.8078 160.268 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C170.983 0.5 203.315 12.7173 228.43 34.8597C253.546 57.0022 269.719 87.5481 273.916 120.767C278.112 153.985 270.044 187.594 251.224 215.286C232.404 242.979 204.126 262.853 171.695 271.18C139.264 279.507 104.909 275.714 75.0763 260.513C45.243 245.313 21.9815 219.748 9.65572 188.617C-2.67005 157.486 -3.21293 122.927 8.12891 91.4235C19.4708 59.9203 41.9179 33.6382 71.2589 17.5078L92.4561 56.0653C72.5042 67.034 57.2401 84.9058 49.5277 106.328C41.8152 127.75 42.1844 151.25 50.5659 172.42C58.9474 193.589 74.7653 210.973 95.0519 221.309C115.338 231.646 138.7 234.225 160.752 228.563C182.805 222.9 202.034 209.386 214.832 190.555C227.63 171.724 233.116 148.87 230.263 126.281C227.409 103.693 216.411 82.9215 199.333 67.8646C182.254 52.8078 160.268 44.5 137.5 44.5V0.5Z" fill="#DB576F" stroke="black" strokeWidth="8" mask="url(#path-2-inside-2_972_38248)"/>
                    <mask id="path-3-inside-3_972_38248" fill="white">
                    <path d="M137.5 0.5C167.276 0.5 196.247 10.1655 220.058 28.0434C243.869 45.9212 261.233 71.0454 269.54 99.6387C277.848 128.232 276.648 158.749 266.124 186.603C255.599 214.456 236.317 238.141 211.176 254.095C186.036 270.05 156.396 277.412 126.712 275.076C97.028 272.74 68.9046 260.831 46.5696 241.14C24.2346 221.449 8.89497 195.04 2.85686 165.883C-3.18125 136.726 0.408444 106.397 13.0863 79.4553L52.8987 98.1896C44.2777 116.51 41.8367 137.134 45.9427 156.961C50.0486 176.787 60.4795 194.746 75.6673 208.135C90.8551 221.525 109.979 229.623 130.164 231.212C150.349 232.8 170.504 227.794 187.6 216.945C204.695 206.096 217.807 189.99 224.964 171.05C232.121 152.11 232.936 131.358 227.287 111.914C221.639 92.4709 209.831 75.3865 193.639 63.2295C177.448 51.0725 157.747 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C167.276 0.5 196.247 10.1655 220.058 28.0434C243.869 45.9212 261.233 71.0454 269.54 99.6387C277.848 128.232 276.648 158.749 266.124 186.603C255.599 214.456 236.317 238.141 211.176 254.095C186.036 270.05 156.396 277.412 126.712 275.076C97.028 272.74 68.9046 260.831 46.5696 241.14C24.2346 221.449 8.89497 195.04 2.85686 165.883C-3.18125 136.726 0.408444 106.397 13.0863 79.4553L52.8987 98.1896C44.2777 116.51 41.8367 137.134 45.9427 156.961C50.0486 176.787 60.4795 194.746 75.6673 208.135C90.8551 221.525 109.979 229.623 130.164 231.212C150.349 232.8 170.504 227.794 187.6 216.945C204.695 206.096 217.807 189.99 224.964 171.05C232.121 152.11 232.936 131.358 227.287 111.914C221.639 92.4709 209.831 75.3865 193.639 63.2295C177.448 51.0725 157.747 44.5 137.5 44.5V0.5Z" fill="#DB6F57" stroke="black" strokeWidth="8" mask="url(#path-3-inside-3_972_38248)"/>
                    <mask id="path-4-inside-4_972_38248" fill="white">
                    <path d="M137.5 0.5C159.199 0.5 180.59 5.63549 199.924 15.4866C219.258 25.3377 235.986 39.6247 248.74 57.1795C261.494 74.7343 269.913 95.0585 273.307 116.49C276.702 137.922 274.976 159.853 268.27 180.49C261.565 201.127 250.071 219.884 234.727 235.227C219.384 250.571 200.627 262.065 179.99 268.77C159.353 275.476 137.422 277.202 115.99 273.807C94.5585 270.413 74.2344 261.994 56.6796 249.24L82.5421 213.643C94.4794 222.316 108.3 228.041 122.873 230.349C137.447 232.657 152.36 231.483 166.393 226.924C180.426 222.364 193.181 214.548 203.615 204.114C214.048 193.681 221.864 180.926 226.424 166.893C230.983 152.86 232.157 137.947 229.849 123.373C227.541 108.8 221.816 94.9793 213.143 83.0421C204.47 71.1048 193.095 61.3896 179.948 54.6909C166.801 47.9921 152.255 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C159.199 0.5 180.59 5.63549 199.924 15.4866C219.258 25.3377 235.986 39.6247 248.74 57.1795C261.494 74.7343 269.913 95.0585 273.307 116.49C276.702 137.922 274.976 159.853 268.27 180.49C261.565 201.127 250.071 219.884 234.727 235.227C219.384 250.571 200.627 262.065 179.99 268.77C159.353 275.476 137.422 277.202 115.99 273.807C94.5585 270.413 74.2344 261.994 56.6796 249.24L82.5421 213.643C94.4794 222.316 108.3 228.041 122.873 230.349C137.447 232.657 152.36 231.483 166.393 226.924C180.426 222.364 193.181 214.548 203.615 204.114C214.048 193.681 221.864 180.926 226.424 166.893C230.983 152.86 232.157 137.947 229.849 123.373C227.541 108.8 221.816 94.9793 213.143 83.0421C204.47 71.1048 193.095 61.3896 179.948 54.6909C166.801 47.9921 152.255 44.5 137.5 44.5V0.5Z" fill="#DB9657" stroke="black" strokeWidth="8" mask="url(#path-4-inside-4_972_38248)"/>
                    <mask id="path-5-inside-5_972_38248" fill="white">
                    <path d="M137.5 0.5C164.327 0.5 190.568 8.34777 212.991 23.0765C235.413 37.8052 253.037 58.7714 263.691 83.3922C274.346 108.013 277.565 135.213 272.953 161.64C268.34 188.068 256.098 212.569 237.733 232.125L205.659 202.005C218.146 188.707 226.471 172.046 229.608 154.075C232.744 136.105 230.555 117.609 223.31 100.867C216.065 84.1246 204.081 69.8675 188.834 59.852C173.586 49.8365 155.742 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C164.327 0.5 190.568 8.34777 212.991 23.0765C235.413 37.8052 253.037 58.7714 263.691 83.3922C274.346 108.013 277.565 135.213 272.953 161.64C268.34 188.068 256.098 212.569 237.733 232.125L205.659 202.005C218.146 188.707 226.471 172.046 229.608 154.075C232.744 136.105 230.555 117.609 223.31 100.867C216.065 84.1246 204.081 69.8675 188.834 59.852C173.586 49.8365 155.742 44.5 137.5 44.5V0.5Z" fill="#DBC657" stroke="black" strokeWidth="8" mask="url(#path-5-inside-5_972_38248)"/>
                    <mask id="path-6-inside-6_972_38248" fill="white">
                    <path d="M137.5 0.5C163.592 0.5 189.146 7.92401 211.176 21.9049C233.207 35.8858 250.804 55.8464 261.914 79.4553C273.023 103.064 277.186 129.347 273.916 155.233C270.646 181.12 260.077 205.541 243.446 225.646L209.543 197.599C220.853 183.928 228.039 167.321 230.263 149.719C232.486 132.116 229.656 114.244 222.101 98.1896C214.547 82.1356 202.58 68.5623 187.6 59.0553C172.619 49.5483 155.243 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C163.592 0.5 189.146 7.92401 211.176 21.9049C233.207 35.8858 250.804 55.8464 261.914 79.4553C273.023 103.064 277.186 129.347 273.916 155.233C270.646 181.12 260.077 205.541 243.446 225.646L209.543 197.599C220.853 183.928 228.039 167.321 230.263 149.719C232.486 132.116 229.656 114.244 222.101 98.1896C214.547 82.1356 202.58 68.5623 187.6 59.0553C172.619 49.5483 155.243 44.5 137.5 44.5V0.5Z" fill="#5ADB57" stroke="black" strokeWidth="8" mask="url(#path-6-inside-6_972_38248)"/>
                    <mask id="path-7-inside-7_972_38248" fill="white">
                    <path d="M137.5 0.5C162.125 0.5 186.298 7.11296 207.493 19.648C228.689 32.183 246.129 50.18 257.992 71.7589C269.855 93.3377 275.706 117.706 274.932 142.319C274.159 166.932 266.79 190.885 253.595 211.676L216.445 188.1C225.417 173.962 230.428 157.673 230.954 140.937C231.48 124.2 227.502 107.63 219.435 92.956C211.368 78.2824 199.508 66.0444 185.095 57.5206C170.682 48.9968 154.245 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C162.125 0.5 186.298 7.11296 207.493 19.648C228.689 32.183 246.129 50.18 257.992 71.7589C269.855 93.3377 275.706 117.706 274.932 142.319C274.159 166.932 266.79 190.885 253.595 211.676L216.445 188.1C225.417 173.962 230.428 157.673 230.954 140.937C231.48 124.2 227.502 107.63 219.435 92.956C211.368 78.2824 199.508 66.0444 185.095 57.5206C170.682 48.9968 154.245 44.5 137.5 44.5V0.5Z" fill="#89DB57" stroke="black" strokeWidth="8" mask="url(#path-7-inside-7_972_38248)"/>
                    <mask id="path-8-inside-8_972_38248" fill="white">
                    <path d="M137.5 0.5C158.469 0.5 179.161 5.29602 197.992 14.5212C216.823 23.7464 233.294 37.1563 246.146 53.7253C258.999 70.2942 267.891 89.5832 272.143 110.117C276.395 130.65 275.895 151.884 270.68 172.195L228.063 161.252C231.609 147.441 231.949 133.002 229.057 119.039C226.166 105.077 220.119 91.9601 211.379 80.6932C202.64 69.4263 191.439 60.3075 178.634 54.0344C165.829 47.7613 151.759 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C158.469 0.5 179.161 5.29602 197.992 14.5212C216.823 23.7464 233.294 37.1563 246.146 53.7253C258.999 70.2942 267.891 89.5832 272.143 110.117C276.395 130.65 275.895 151.884 270.68 172.195L228.063 161.252C231.609 147.441 231.949 133.002 229.057 119.039C226.166 105.077 220.119 91.9601 211.379 80.6932C202.64 69.4263 191.439 60.3075 178.634 54.0344C165.829 47.7613 151.759 44.5 137.5 44.5V0.5Z" fill="#57DBB3" stroke="black" strokeWidth="8" mask="url(#path-8-inside-8_972_38248)"/>
                    <mask id="path-9-inside-9_972_38248" fill="white">
                    <path d="M137.5 0.5C172.473 0.5 206.131 13.8264 231.625 37.7668C257.119 61.7073 272.533 94.4626 274.729 129.366L230.816 132.129C229.322 108.395 218.841 86.121 201.505 69.8414C184.169 53.5619 161.281 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C172.473 0.5 206.131 13.8264 231.625 37.7668C257.119 61.7073 272.533 94.4626 274.729 129.366L230.816 132.129C229.322 108.395 218.841 86.121 201.505 69.8414C184.169 53.5619 161.281 44.5 137.5 44.5V0.5Z" fill="#578CDB" stroke="black" strokeWidth="8" mask="url(#path-9-inside-9_972_38248)"/>
                    <mask id="path-10-inside-10_972_38248" fill="white">
                    <path d="M137.5 0.5C157.74 0.5 177.731 4.96843 196.045 13.5863C214.359 22.2041 230.544 34.7589 243.446 50.3542L209.543 78.4008C200.77 67.796 189.764 59.2588 177.31 53.3987C164.857 47.5385 151.263 44.5 137.5 44.5V0.5Z"/>
                    </mask>
                    <path d="M137.5 0.5C157.74 0.5 177.731 4.96843 196.045 13.5863C214.359 22.2041 230.544 34.7589 243.446 50.3542L209.543 78.4008C200.77 67.796 189.764 59.2588 177.31 53.3987C164.857 47.5385 151.263 44.5 137.5 44.5V0.5Z" fill="#8357DB" stroke="black" strokeWidth="8" mask="url(#path-10-inside-10_972_38248)"/>
                    </svg>
                </div>
            );
        case 'partable':
            return (
                <div className="donut__partable">
                    <svg width="115" height="75" viewBox="0 0 115 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#donutPartable)">
                        <circle opacity="0.1" cx="2.5" cy="72.5" r="64.5" stroke="#D9D9D9" strokeWidth="16"/>
                        <circle className="offset" cx="2.5" cy="72.5" r="64.5" stroke="currentColor" transform="rotate(-90 2.5 72.5)" strokeDasharray="100" strokeDashoffset="95" strokeWidth="16"/>
                        </g>
                        <defs>
                        <clipPath id="donutPartable">
                            <rect width="115" height="75" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
            );
        default:
            return <div></div>
    }
}

export default Donut