import './Footer.scss'
import Image from '@/components/UI/Image/Image'
import bgImage from '../Main/assets/bg.png'
import bgPlaceholderImage from '../Main/assets/bg-placeholder.png'
import LinearBorder from '@/components/Background/LinearBorder'
import Icon from '@/components/UI/Icon/Icon'
import parse from 'html-react-parser'
import { $t } from '@/i18n/i18n'
import Button from '@/components/UI/Button/Button'
import DelayedImage from "@/components/UI/Image/DelayedImage"

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer__content'>
                <Icon className='logo' label='logoW' />
                <div className='content__buttons'>
                    <Button link={$t('components.buttons.whitepaper.link')} label="document">{$t('components.buttons.whitepaper.text')}</Button>
                    <Button link={$t('components.buttons.twitter.link')} label="twitter">{$t('components.buttons.twitter.text')}</Button>
                </div>
                <div className='content__socials'>
                    {$t('pages.home.footer.socials').map((_, i) => (
                        <a key={i} href={_.link} target="_blank" rel="noreferrer">
                            <Icon label={_.icon} />
                        </a>
                    ))}
                </div>
            </div>

            <p className='footer__copyright'>{ parse($t('pages.home.footer.copyright')) }</p>

            <div className='footer__background'>
                {/* <Image src={bgImage} width="1440" height="800" delayTime="0" visibleByDefault="true" /> */}
                <DelayedImage src={bgImage} placeholder={ bgPlaceholderImage } width="1440" height="800"/>
                <LinearBorder top />
            </div>
        </div>
    )
}

export default Footer