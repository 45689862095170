import './assets/index.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ApiContextProvider from "./api/context"
import ModalsContextProvider from "./components/Modals/context"
import Modals from "@/components/Modals/Modals"
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { WagmiProvider } from 'wagmi'
import { config } from '../src/web3/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FarmingLayoyt from './layouts/FarmingLayoyt'
import ConnectWalletLayout from './layouts/ConnectWalletLayout'
// import ErrorPopupLayout from './layouts/ErrorPopupLayout'

const queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApiContextProvider>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <FarmingLayoyt>
          <ConnectWalletLayout>
            {/* <ErrorPopupLayout> */}
              <ModalsContextProvider>
                <RouterProvider router={router} />
                <Modals />
              </ModalsContextProvider>
            {/* </ErrorPopupLayout> */}
          </ConnectWalletLayout>
        </FarmingLayoyt>
      </QueryClientProvider>
    </WagmiProvider>
  </ApiContextProvider>
)