import "./Home.scss";

import { Scroll, Slides, Pagination } from "@/components/ScreenScroll/ScreenScroll";
import { screens } from "./constants";

import Main from "./components/Main/Main";
import About from "./components/About/About";
import Features from "./components/Features/Features";
import Game from "./components/Game/Game";
import Generation from "./components/Generation/Generation";
import Genesis from "./components/Genesis/Genesis";
import Park from "./components/Park/Park";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import Roadmap from "./components/Roadmap/Roadmap";
import Team from "./components/Team/Team";
import Faq from "./components/Faq/Faq";
import Footer from "./components/Footer/Footer";

const Home = () => {
  return (
    <Scroll>
      <Slides>
        <Main screen-id={screens.MAIN} pagination-tip="🏠" />
        <About screen-id={screens.ABOUT} pagination-tip="👋" />
        <Features screen-id={screens.FEATURES} pagination-tip="🤩"/>
        <Game screen-id={screens.GAME} pagination-tip="📜" slides-count="4"/>
        <Generation screen-id={screens.GENERATION} pagination-tip="👨‍🎨" slides-count="3" />
        {/* <Genesis screen-id={screens.GENESIS} pagination-tip="NFT’s Utility" slides-count="2" /> */}
        <Park screen-id={screens.PARK} pagination-tip="🌲" slides-count="3" />
        {/* <Tokenomics screen-id={screens.TOKENOMICS} pagination-tip="Tokenomics" /> */}
        {/* <Roadmap screen-id={screens.ROADMAP} pagination-tip="Roadmap" /> */}
        <Team screen-id={screens.TEAM} pagination-tip="🫂" inner-scroll={true} />
        {/* <Faq screen-id={screens.FAQ} pagination-tip="FAQ" inner-scroll={true} /> */}
        <Footer screen-id={screens.FOOTER} pagination-tip="😉" />
      </Slides>
      <Pagination/>
    </Scroll>
  );
};

export default Home;
