import './Roadmap.scss'
import { $t } from '@/i18n/i18n'
import cn from 'classnames'
import Background from '@/components/Background/Background'
import backgroundImage from '../Tokenomics/assets/background-2.png'
import LinearBorder from '@/components/Background/LinearBorder'
import { useState, useEffect, useRef } from 'react'
import StCheckbox from '@/components/UI/Forms/StCheckbox/StCheckbox'
import { stages } from '@/components/UI/Forms/StCheckbox/StCheckbox'
import { Scrollbars } from 'react-custom-scrollbars-2'
import DragToScroll from '@/animator/js/libs/dragToScroll'

const Roadmap = () => {
    const [activeId, setActiveId] = useState(1)
    const activeLists = useRef({})

    useEffect(() => {
        const doms = Array.from(document.querySelectorAll('.list__content'))
        const drags = doms.map( dom => new DragToScroll(dom) )
        doms.forEach((dom, i) => {
            if (activeLists.current[i]) {
                const offsetX = activeLists.current[i].getBoundingClientRect().left - window.innerWidth / 2 + ( activeLists.current[i].getBoundingClientRect().width / 2 )
                dom.scrollLeft = offsetX
            } else {
                dom.scrollLeft = window.innerWidth / 2
            }
        })
        return () => drags.forEach( item => item.destroy() )
    }, [])

    return (
        <div className='roadmap'>
            <div className='roadmap__content'>
                <div className='content__header'>
                    <h2>{$t('pages.home.roadmap.title')}</h2>
                    <div className='header__tabs'>
                        {$t('pages.home.roadmap.list').map((_, i) => <div onClick={() => setActiveId(i)} className={activeId === i ? '-active' : ''} key={i}>{_.date}</div>)}
                    </div>
                </div>
                <div className='content__wrappers'>
                    {$t('pages.home.roadmap.list').map((_, i) => {
                        
                        activeLists.current[i] = null

                        return (
                        <div key={i} className='content__container'>
                            <Scrollbars
                                className={cn('list', activeId === i ? '-active' : '')}
                                renderView={props => <div {...props} className='list__content' />}
                                renderTrackHorizontal={props => <div {...props} className='list__track' />}
                                renderThumbHorizontal={props => <div {...props} className='list__thumb' />}
                                thumbSize={100}
                                universal={true}
                            >
                                {_.quartal.map((__, i1) => (
                                    <div key={_.date + __.name} className='list__quartal'>
                                        <h5>{__.name}<span>{ _.date }</span></h5>
                                        {__.body.map((___, i2) => {
                                            let stage = ___.done ? stages.DONE : stages.DEFAULT
                                            if (__.body[i2 - 1]) {
                                                if (__.body[i2 - 1].done && !___.done) {
                                                    stage = stages.ACTIVE
                                                }
                                            } else if (_.quartal[i1 - 1]) {
                                                if (_.quartal[i1 - 1].body.at(-1).done && !___.done) {
                                                    stage = stages.ACTIVE
                                                } 
                                            }
                                            if (stage === stages.ACTIVE){
                                                return (
                                                    <StCheckbox cref={(r) => activeLists.current[i] = r} key={_.date + __.name + ___.text} stage={stage}>{___.text}</StCheckbox>
                                                )
                                            }
                                            return (
                                                <StCheckbox key={_.date + __.name + ___.text} stage={stage}>{___.text}</StCheckbox>
                                            )
                                        })}
                                    </div>
                                ))}
                            </Scrollbars>
                        </div>
                    )})}
                </div>
            </div>
            <LinearBorder bottom />
            <div className='roadmap__background'>
                <Background image={backgroundImage} className="background_1" />
            </div>
        </div>
    )
}

export default Roadmap