import { useState, useEffect } from 'react';
import { useBlockNumber } from 'wagmi';

export function useBlockNumberHook() {
    const [currentBlockNumber, setCurrentBlockNumber] = useState(null);
    const { data: blockNumber, isError } = useBlockNumber({
        watch: true,
    });

    useEffect(() => {
        if (blockNumber) {
            console.log('Block number changed: ', blockNumber);
            setCurrentBlockNumber(blockNumber);
        }
    }, [blockNumber]);

    return { currentBlockNumber, isError };
}
