import { create } from 'zustand'

const initialState = {

    seqBalance: 0.4,
    nftsList: [],
    nftsPer24hours: 0,
    popupConnectWallet: false,
    popupError: '',
    claimFarming: () => { },
    enableFarming: () => { },






}

export const useSequoiaStore = create((set) => ({
    ...initialState,


    setseqBalance: (seqBalance) => set((state) => ({ seqBalance })),
    setnftsList: (nftsList) => set((state) => ({ nftsList })),
    setclaimFarming: (claimFarming) => set((state) => ({ claimFarming })),
    setenableFarming: (enableFarming) => set((state) => ({ enableFarming })),
    setpopupConnectWallet: (popupConnectWallet) => set((state) => ({ popupConnectWallet })),
    setpopupError: (popupError) => set((state) => ({ popupError })),





    reset: () => set(initialState)

}))
