import './Accordion.scss'
import { useState } from 'react'
import cn from 'classnames'

const Accordion = ({ title = '', body = '', children}) => {
    const [ isActive, setIsActive ] = useState(false)
    return (
        <div onClick={() => setIsActive(!isActive)} className={cn('accordion', isActive ? '-active' : '')}>
            <h5>{ title }</h5>
            <div className='accordion__body'>
                <div className='desciption'>
                    {children}
                    <p>{ body }</p>
                </div>
            </div>
        </div>
    )
}

export default Accordion