import './Generation.scss'
import Nft from './nft'
import { $t } from '@/i18n/i18n'
import Icon from '@/components/UI/Icon/Icon'
import { toLower } from 'lodash'

const Generation = () => {
    return (
        <div className='generation'>
            <div className='generation__content'>
                <h2 className='content__title'><span data-text={ $t('pages.home.generation.title_text') }>{ $t('pages.home.generation.title') }</span></h2>
                <div className='content__wrapper'>
                    <p>{ $t('pages.home.generation.description.text_1') }</p>
                    {/* <div className='stats'>
                        { $t('pages.home.generation.description.stats').map( ( _, i ) => (
                            <div key={i} className='stats__card'>
                                <Icon label={toLower(_.subtitle)} />
                                <h5>{ _.title }</h5>
                                <p>{ _.subtitle }</p>
                            </div>
                        )) }
                    </div> */}
                </div>
                <h4 className='content__subtitle'><span>{ $t('pages.home.generation.subtitle') }</span></h4>
            </div>
            <div className='generation__canvas'>
                <div className='canvas__container'>
                    <div className='nfts'>
                        <div className='nfts__object o-1'><Nft label="common" /></div>
                        <div className='nfts__object o-2'><Nft label="rare" /></div>
                        <div className='nfts__object o-3'><Nft label="epic" /></div>
                        <div className='nfts__object o-4'><Nft label="legendary" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Generation