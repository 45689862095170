import { useState, useEffect, useMemo } from 'react';
import { useReadContract, useWriteContract } from 'wagmi';
import { useBlockNumberHook } from './useBlockNumber';
import { alphaGenerationABI } from './alphaGeneration';
import { useWallet } from '../useWallet';
import { multicall, readContract, getGasPrice, estimateGas } from '@wagmi/core'
import { config } from '../config';
import { farmingNFTAbi } from './farmingNFT';
import { rarityRegistryAbi } from './rarityRegistry';

export function useMySpace() {

    const [isError, setIsError] = useState(false);
    const [nftsData, setNftsData] = useState([]);
    const [pendingReward, setPendingReward] = useState(false);
    const [summaryDailyReward, setSummaryDailyReward] = useState('-');
    const [rewardsPerBlock, setRewardsPerBlock] = useState(false);
    const [rarity, setRarity] = useState(false);
    const [lastReward, setlastReward] = useState();
    const { address } = useWallet();


    const getAccountBalance = async () => {

        const result = await readContract(config, {
            abi: alphaGenerationABI,
            address: '0x6cdc97c6bdD746c186d78bBe861aA78c38412B1F',
            functionName: 'balanceOf',
            args: [address],

        })
        return result

    };

    const getNftsList = async (accountBalance) => {
        const alphaGenerationContract = {
            address: '0x6cdc97c6bdD746c186d78bBe861aA78c38412B1F',
            abi: alphaGenerationABI,
        };

        const createTokenQueryArray = (balance,) => {
            return Array.from({ length: balance }, (_, index) => {
                // console.log(`Processing index ${index} - ${balance}`); // выводим индекс в консоль
                return ({
                    ...alphaGenerationContract,
                    functionName: 'tokenOfOwnerByIndex',
                    args: [address, index],
                });
            });
        };




        const result = await multicall(config, {
            contracts: createTokenQueryArray(Number(`${accountBalance}`))
        })

        // setNfts(result)
        return result
        // console.log('resultNFTS',result)
    }

    const getPendingReward = async (tokenIds) => {
        const farmingNFTContract = {
            address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C', // контракт FarmingNFT
            abi: farmingNFTAbi,
        };

        const createPendingRewardQueryArray = (tokenIds) => {
            return tokenIds.map((tokenId) => ({
                ...farmingNFTContract,
                functionName: "pendingReward",
                args: [tokenId.result],
            }));
        };

        const result = await multicall(config, {
            contracts: createPendingRewardQueryArray(tokenIds)
        });

        setPendingReward(result)
        return result

    };

    const getRewardsPerBlock = async (tokenIds) => {

        const farmingNFTContract = {
            address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C', // контракт FarmingNFT
            abi: farmingNFTAbi,
        };

        const createPendingRewardQueryArray = (tokenIds) => {
            if (tokenIds) {


                return tokenIds.map((tokenId) => ({
                    ...farmingNFTContract,
                    functionName: "rewardsPerBlock",
                    args: [tokenId.result ? tokenId.result.rarity : null],
                }));
            }
        };

        const result = await multicall(config, {
            contracts: createPendingRewardQueryArray(tokenIds)
        });
        setRewardsPerBlock(result)
        return result


    };

    const getRarity = async (tokenIds) => {
        const farmingNFTContract = {
            address: '0xb56EA7842914cfFc64F6220BA426e4e80b4dA5c7', // контракт FarmingNFT
            abi: rarityRegistryAbi,
        };

        const createRarityQueryArray = (tokenIds) => {
            return tokenIds.map((tokenId) => ({
                ...farmingNFTContract,
                functionName: "get",
                args: [tokenId.result],
            }));
        };

        const result = await multicall(config, {
            contracts: createRarityQueryArray(tokenIds)
        });
        setRarity(result)
        return result

    };

    const getLastReward = async (tokenIds) => {
        const farmingNFTContract = {
            address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C', // контракт FarmingNFT
            abi: farmingNFTAbi,
        };


        const createRarityQueryArray = (tokenIds) => {
            return tokenIds.map((tokenId) => ({
                ...farmingNFTContract,
                functionName: "lastReward",
                args: [tokenId.result],
            }));
        };

        const result = await multicall(config, {
            contracts: createRarityQueryArray(tokenIds)
        });
        setlastReward(result)

        return result

    };

    const createNFTsData = (anfts, argpendingReward, argrewardsPerBlock, argrarity, arglastReward) => {

        if (anfts.some(nft => nft.result == undefined) || argpendingReward.some(pr => pr.result == undefined) || argrewardsPerBlock.some(rpb => rpb.result == undefined) || argrarity.some(r => r.result == undefined) || arglastReward.some(lr => lr.result == undefined)) {
            if (anfts.some(nft => nft.result == undefined)) {
                // console.log('nfts.result is undefined');
            }
            if (argpendingReward.some(pr => pr.result == undefined)) {
                // console.log('pendingReward.result is undefined');
            }
            if (argrewardsPerBlock.some(rpb => rpb.result == undefined)) {
                // console.log('rewardsPerBlock.result is undefined');
            }
            if (argrarity.some(r => r.result == undefined)) {
                // console.log('rarity.result is undefined');
            }
            if (arglastReward.some(lr => lr.result == undefined)) {
                // console.log('lastReward.result is undefined');
            }

            createNewNFTsData();
        }

        const NFTsData = anfts.map((nft, id) => {
            return {
                id: nft.result,
                image: 'https://nftcalendar.io/storage/uploads/events/2022/11/91tZ6b5KpnNCUXYUE1H2016NxMZL63TiH32LJMpL.jpg',
                rewardPending: argpendingReward[id].result,
                rewardPerBlock: argrewardsPerBlock[id].result,
                rarity: argrarity[id].result.rarity,
                lastReward: arglastReward[id].result

            }
        })
        const updatedNFTsData = NFTsData.map((nft, index) => {
            const previousNft = nftsData.find(prevNft => prevNft.id == nft.id);
            if (previousNft) {
                return {
                    ...nft,
                    rewardPending: Number(previousNft.rewardPending) > Number(nft.rewardPending) ? previousNft.rewardPending : nft.rewardPending,
                    rewardPerBlock: Number(previousNft.rewardPerBlock) > Number(nft.rewardPerBlock) ? previousNft.rewardPerBlock : nft.rewardPerBlock,
                    rarity: Number(previousNft.rarity) > Number(nft.rarity) ? previousNft.rarity : nft.rarity,
                    lastReward: Number(previousNft.lastReward) > Number(nft.lastReward) ? previousNft.lastReward : nft.lastReward,
                };
            }
            return nft;
        });

        const missingNFTs = nftsData.filter(prevNft => !NFTsData.some(nft => nft.id == prevNft.id));
        const combinedNFTsData = [...updatedNFTsData, ...missingNFTs];

        setNftsData(combinedNFTsData);
        console.log('data updated')
        return NFTsData

    }

    const createNewNFTsData = async () => {
        try {


            // const accountBalance = await getAccountBalance();
            // console.log('accountBalance', accountBalance);
            let accountBalance = 0;

            // Функция для проверки баланса
            const checkAccountBalance = async () => {
                accountBalance = await getAccountBalance();
                // console.log('accountBalance', accountBalance);

                if (Number(accountBalance) === 0) {
                    console.log('Balance is 0. Retrying in 1 second...');
                    return new Promise((resolve) => setTimeout(() => resolve(checkAccountBalance()), 1000)); // Повторная проверка через 1 секунду
                }

                return accountBalance;
            };

            // Проверяем и ждем, пока баланс не станет больше 0
            await checkAccountBalance();

            const nftsList = await getNftsList(accountBalance);
            // console.log('nftsList', nftsList);

            const rarityRes = await getRarity(nftsList);
            // console.log('rarityRes', rarityRes);

            const rewardsPerBlockRes = await getRewardsPerBlock(rarityRes);
            // console.log('rewardsPerBlockRes', rewardsPerBlockRes);

            const lastRewardRes = await getLastReward(nftsList);
            // console.log('lastRewardRes', lastRewardRes);

            const pendingRewardRes = await getPendingReward(nftsList);
            // console.log('pendingRewardRes', pendingRewardRes);

            const createNFTsresult = createNFTsData(nftsList, pendingRewardRes, rewardsPerBlockRes, rarityRes, lastRewardRes);
            // console.log('createNFTsresult', createNFTsresult)
        } catch (error) {
            console.log('Error while updating NFTs data');
            setIsError(true);
        }
    };
    const updateNFTsData = async () => {
        try {


            // const accountBalance = await getAccountBalance();
            // console.log('accountBalance', accountBalance);
            let accountBalance = 0;

            // Функция для проверки баланса
            const checkAccountBalance = async () => {
                accountBalance = await getAccountBalance();
                // console.log('accountBalance', accountBalance);

                if (Number(accountBalance) === 0) {
                    console.log('Balance is 0. Retrying in 1 second...');
                    return new Promise((resolve) => setTimeout(() => resolve(checkAccountBalance()), 1000)); // Повторная проверка через 1 секунду
                }

                return accountBalance;
            };

            // Проверяем и ждем, пока баланс не станет больше 0
            await checkAccountBalance();

            const nftsList = await getNftsList(accountBalance);
            // console.log('nftsList', nftsList);

            const rarityRes = await getRarity(nftsList);
            // console.log('rarityRes', rarityRes);

            const rewardsPerBlockRes = await getRewardsPerBlock(rarityRes);
            // console.log('rewardsPerBlockRes', rewardsPerBlockRes);

            const lastRewardRes = await getLastReward(nftsList);
            // console.log('lastRewardRes', lastRewardRes);

            const pendingRewardRes = await getPendingReward(nftsList);
            // console.log('pendingRewardRes', pendingRewardRes);

            const createNFTsresult = createNFTsData(nftsList, pendingRewardRes, rewardsPerBlockRes, rarityRes, lastRewardRes);
            // console.log('createNFTsresult', createNFTsresult)
        } catch (error) {
            console.log('Error while updating NFTs data');
            setIsError(true);
        }
    };

    useEffect(() => {
        if (!address) return;
        createNewNFTsData();
    }, [address]);


    useEffect(() => {
        let isMounted = true;

        const updateDataWithDelay = async () => {
            while (isMounted) {
                await updateNFTsData(); // Дождаться завершения
                await new Promise((resolve) => setTimeout(resolve, 5000)); // Ждать 5 секунд
            }
        };

        if (nftsData.length > 0) {
            updateDataWithDelay();
        }

        return () => {
            isMounted = false; // Останавливаем цикл при размонтировании компонента
        };
    }, [nftsData]);


    const {
        data,
        isPending,
        writeContractAsync,
        error
    } = useWriteContract()




    const enableFarming = async () => {



        const nftsForEnable = nftsData.filter((nft) => nft.lastReward == 0).map((nft) => nft.id)
        console.log('nftsData', nftsData)
        console.log('nftsForEnable', nftsForEnable)
        if (nftsForEnable.length > 0) {

            const gasPrice = await getGasPrice(config);
            const gasEstimate = await estimateGas(config, {
                address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C',
                abi: farmingNFTAbi,
                functionName: 'enable',
                args: [nftsForEnable.map(Number)],

            });

            // eslint-disable-next-line no-undef
            const gasEstimateNew = gasEstimate * BigInt(10)
            // console.log('nftsForEnable', nftsForEnable)
            await writeContractAsync({
                address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C',
                abi: farmingNFTAbi,
                functionName: 'enable',
                args: [nftsForEnable.map(Number)],

                gas: gasEstimateNew,
                gasPrice: gasPrice,
                overrides: {
                    gasPrice: gasPrice,
                    gasLimit: gasEstimateNew,
                },


            })
        } else {
            console.log('farming active for all NFTs')
        }



    }






    const claimFarming = async () => {



        console.log('claimFarming nftsData', nftsData)
        const nftsForClaim = nftsData.filter((nft) => nft.lastReward != 0 && nft.rarity > 0).map((nft) => nft.id)
        // console.log('nftsForClaim', nftsForClaim)
        if (nftsForClaim.length > 0) {
            const gasPrice = await getGasPrice(config);
            const gasEstimate = await estimateGas(config, {
                address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C',
                abi: farmingNFTAbi,
                functionName: 'earnBatch',
                args: [nftsForClaim.map(Number)],

            });

            // eslint-disable-next-line no-undef
            const gasEstimateNew = gasEstimate * BigInt(25)
            console.log('nftsForClaim', nftsForClaim)
            const result = await writeContractAsync({
                address: '0x3A6045Bc28957590EE6F222add71EdcDD436B84C',
                abi: farmingNFTAbi,
                functionName: 'earnBatch',
                args: [nftsForClaim.map(Number)],
                // gas: 1000000,
                gas: gasEstimateNew,
                gasPrice: gasPrice,
                overrides: {
                    gasPrice: gasPrice,
                    gasLimit: gasEstimateNew,
                },


            })
            if (result) {
                setNftsData(nftsData.map(nft => ({ ...nft, rewardPending: 0 })));
            }



        } else {
            console.log('no nftsForClaim')
        }


    };

    return { nftsData, enableFarming, claimFarming }



}