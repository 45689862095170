import './Card.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'
import { rarity } from '../../constants'
import DelayedImage from "@/components/UI/Image/DelayedImage"

const Card = ({...props}) => {




    return (
        <div className={cn('card', '-common' ,)}>
            <picture>
                {/* <DelayedImage className='card__tree-image' placeholder={props.delayedImage} src={props.image} width="230" height="230"/> */}
                <img className='card__tree-image' src={require(`./assets/seqcard${props.cardNumber}.png`)} width="230" height="230"/>
            </picture>
            {/* <div className='card__rarity'>
                {props.rarity}
            </div> */}
            <div className='card__footer'>
                <p className='card__footer__tag'>
                    #{props.tag}
                </p>
                <div className='card__footer__card-number'>
                    <p>
                        {(Number(props.rewardPerBlock)/(10**18)*31680).toFixed(1)}
                    </p>
                    <div>
                        <span>
                            {$t('pages.myspace.trees.seqdayfirst')}
                        </span>
                        <span>
                            {$t('pages.myspace.trees.seqdaysecond')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card