import React, { Children, useEffect } from 'react'
import { useMySpace } from '../web3/contracts/useMySpace';
import { useSequoiaStore } from '../store/store';
const FarmingLayoyt = ({ children }) => {

    const nftsList = useSequoiaStore((state) => state.nftsList)

    const setnftsList = useSequoiaStore((state) => state.setnftsList)
    const setclaimFarming = useSequoiaStore((state) => state.setclaimFarming)
    const setenableFarming = useSequoiaStore((state) => state.setenableFarming)
    const { nftsData, enableFarming, claimFarming } = useMySpace();

    
    useEffect(() => {
        setclaimFarming(claimFarming)
        setenableFarming(enableFarming)
        if (nftsData.length < nftsList.length) {

        } else {
            setnftsList(nftsData)
        }

    }, [nftsData])

    const setseqBalance = useSequoiaStore((state) => state.setseqBalance)
    useEffect(() => {

        const calculateTotalReward = () => {
            const total = nftsData.reduce((acc, nft) => acc + Number(nft.rewardPending), 0);
            setseqBalance(total)
        };

        calculateTotalReward();
    }, [nftsData]);


    return (
        <div>
            <div style={{ position: 'fixed', opacity: 0, top: 0, left: 0 }}>
                {nftsList.map((item, i) => {
                    // console.log(item)
                    return (
                        <div key={`ìtemlayout${i}`}>
                            {i}
                        </div>

                    )
                })}
            </div>
            {children}
        </div>
    )
}

export default FarmingLayoyt