import React, { Children, useEffect } from 'react'
import { useMySpace } from '../web3/contracts/useMySpace';
import { useSequoiaStore } from '../store/store';
import './ConnectWallet.scss'
import { useWallet } from '../web3/useWallet';
const ConnectWalletLayout = ({ children }) => {

    const popupConnectWallet = useSequoiaStore((state) => state.popupConnectWallet)
    const setpopupConnectWallet = useSequoiaStore((state) => state.setpopupConnectWallet)

    const { connectWalletMethod } = useWallet();

    
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape' && popupConnectWallet) {
                setpopupConnectWallet(false);
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [popupConnectWallet, setpopupConnectWallet]);

    return (
        <div>
            {popupConnectWallet ? <div className="mint__popupWrapper" onClick={() => {
                        // setpopupConnectWallet(false)
                    }}>

                <div className="mint__popup">
                    <p className="mint__popupCloseButton" onClick={() => {
                        setpopupConnectWallet(false)
                    }}>x</p>

                    <div className="mint__popupConnectors">
                        <div className="mint__popupConnectorsItem" onClick={() => {
                            connectWalletMethod('MetaMask')
                        }}>
                            <div className="mint__popupConnectorsItemWallet">
                                <img src={require('./assets/metamask.png')}></img>
                            </div>
                            MetaMask
                        </div>
                        <div className="mint__popupConnectorsItem" onClick={() => {
                            connectWalletMethod('WalletConnect')
                        }}>
                            <div className="mint__popupConnectorsItemWallet">
                                <img src={require('./assets/Walletconnect-logo.png')}></img>
                            </div>
                            WalletConnect
                        </div>
                    </div>

                </div>


            </div> : <></>}

            {children}
        </div>
    )
}

export default ConnectWalletLayout