import { useEffect } from 'react';
import { useConnect, useAccount, useDisconnect } from 'wagmi';
import { watchConnections } from '@wagmi/core'
import { config } from './config'
import { polygonAmoy } from 'wagmi/chains';
import { useSequoiaStore } from '../store/store';

export const useWallet = () => {
    const { connect, connectors, isLoading, pendingConnector } = useConnect();
    const { disconnect } = useDisconnect();
    const { address, isConnected } = useAccount();

    const setpopupConnectWallet = useSequoiaStore((state) => state.setpopupConnectWallet)



    // Метод для автоматического подключения
    const connectWallet = async () => {
        setpopupConnectWallet(true)
    };

    const connectWalletMethod = async (walletName) => {
        try {

            const connector = connectors.find(c => c.name == walletName) || connectors.find(c => c.name == 'MetaMask') || // Попробовать MetaMask
                // connectors.find(c => c.id === 'injected') || // Или injected
                connectors.find(c => c.name == '"WalletConnect"'); // Или WalletConnect как fallback

            if (!connector) throw new Error('No connectors');
            setpopupConnectWallet(false)
            await connect({ connector });

            const { account, chain } = await connect({ connector });
            console.log('Wallet connected');

            // Проверяем текущую сеть
            const currentChainId = chain.id;
            const polygonAmoyChainId = '0x13882'; // Chain ID сети Polygon Amoy

            if (currentChainId !== polygonAmoyChainId) {
                try {
                    // Пытаемся переключиться на сеть Polygon Amoy
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: polygonAmoyChainId }],
                    });
                    console.log('Switched to Polygon Amoy network');
                } catch (switchError) {
                    // Если сеть не найдена, пытаемся ее добавить
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    polygonAmoyChainId,
                                    chainName: polygonAmoy.name, // Название сети polygonAmoy
                                    rpcUrls: polygonAmoy.rpcUrls, // URL RPC сети polygonAmoy
                                    nativeCurrency: polygonAmoy.nativeCurrency, // Валюта сети polygonAmoy
                                    blockExplorerUrls: polygonAmoy.blockExplorerUrls, // URL обозревателя блоков
                                }],
                            });
                            console.log('Polygon Amoy network added and switched');
                        } catch (addError) {
                            console.error('Failed to add the network:', addError);
                        }
                    } else {
                        console.error('Failed to switch the network:', switchError);
                    }
                }

            }

            console.log('Wallet connected');
        } catch (error) {
            console.error('Error during connecting:', error);
        }
    };

    // Метод для отключения кошелька
    const disconnectWallet = () => {
        try {
            if (isConnected) {
                disconnect();
                console.log('Wallet disconnected');
            }
        } catch (error) {
            console.error('Error during disconnecting:', error);
        }
    };

    const changeNetwork = async () => {
        console.log('start chagning network')
        // let connector = connectors[0];
        const connector = connectors.find(c => c.name == 'MetaMask') || // Попробовать MetaMask
            // connectors.find(c => c.id === 'injected') || // Или injected
            connectors.find(c => c.name == 'WalletConnect'); // Или WalletConnect как fallback

        const provider = await connector.getProvider();
        const chainId = '0x13882'; // Используем ID сети polygonAmoy

        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId }],
            });

        } catch (switchError) {
            // Если сеть не добавлена, добавляем ее
            if (switchError.code === 4902) {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId,
                        chainName: polygonAmoy.name, // Название сети polygonAmoy
                        rpcUrls: polygonAmoy.rpcUrls, // URL RPC сети polygonAmoy
                        nativeCurrency: polygonAmoy.nativeCurrency, // Валюта сети polygonAmoy
                        blockExplorerUrls: polygonAmoy.blockExplorerUrls, // URL обозревателя блоков
                    }],
                });
            } else {
                console.log('Error switching chain:', switchError);
            }
        }
    }

    const unwatch = watchConnections(config, {
        onChange(data) {
            // console.log('Connections changed!', data)
            // changeNetwork()
        },
    })
    useEffect(() => {
        unwatch()
    }, [])


    return {
        connectWallet,
        disconnectWallet,
        isLoading,
        pendingConnector,
        isConnected,
        address,
        connectWalletMethod
    };
};
