import './MySpace.scss'
import { $t } from '@/i18n/i18n'
import SwitchBox from '../../components/UI/Forms/Switch/SwitchBox'
import Statistics from './components/Statistics/Statistics'
import { cards } from './components/constants'
import Card from './components/TreeNFTs/Card/Card'
import EarnedImage from './components/Statistics/assets/EarnedImage.png'
import SEQPoolImage from './components/Statistics/assets/SEQPoolImage.png'
import FarmTimeImage from './components/Statistics/assets/FarmTimeImage.png'
import TreeNFTs from './components/TreeNFTs/TreeNFTs'
import whiteBaloonImage from './components/assets/whiteBaloonImage.png'
import Farming from './components/Farming/Farming'
import FAQ from './components/FAQ/FAQ'
import JoinUs from './components/JoinUs/JoinUs'
import Footer from './components/Footer/Footer'
import renderer from '@/animator/js/renderer'
import { useEffect } from 'react'
import { useEnableFarming, useGetOwner, usePendingReward } from '../../web3/contracts/farmingNFT'
import { alphaGenerationABI, useBalanceOf, useTokenOfOwnerByIndex } from '../../web3/contracts/alphaGeneration'
import { useWriteContract } from 'wagmi'
import { axiosInstance, handle } from '../../api/nftImages'
import { useNftBalance } from '../../web3/contracts/useNFTBalance'
import { useFarm } from '../../web3/contracts/useFarm'
import { useAccount } from 'wagmi';
import { useSequoiaStore } from '../../store/store'

const MySpace = () => {
    // const setseqBalance = useSequoiaStore((state) => state.setseqBalance)

    const nftsList = useSequoiaStore((state) => state.nftsList)

    const enableFarming = useSequoiaStore((state) => state.enableFarming)
    const claimFarming = useSequoiaStore((state) => state.claimFarming)
    useEffect(() => {
        renderer.startRender()

        return () => {
            renderer.stopRender()
            // setseqBalance(0)
        }
    }, [])
    const { address, isConnected } = useAccount();

    useEffect(() => {
        if (!isConnected) {
            window.location.href = '/';
        }
    }, [isConnected]);



   


    return (
        <div id="space" className='my-space'>
            <Farming claimFarming={claimFarming} enableFarming={enableFarming} nftsData={nftsList} ></Farming>
            <TreeNFTs nftsData={nftsList}></TreeNFTs>
            <FAQ></FAQ>
            <JoinUs></JoinUs>
            <Footer></Footer>
        </div>
    )
}

export default MySpace