import { createConfig, http } from 'wagmi';
import { polygonAmoy } from 'wagmi/chains';
// import { injected } from 'wagmi/connectors';
import { walletConnect } from 'wagmi/connectors';
import { metaMask } from 'wagmi/connectors';

export const config = createConfig({
  chains: [polygonAmoy], // Измените на Polygon
  transports: {
    [polygonAmoy.id]: http(),
  },
  connectors: [
    metaMask(),
    // injected(),
    walletConnect({
      projectId: '46391b0e5d0f45aa11d822d388d155f9',
    }),
  ],
  autoConnect: false,
});
