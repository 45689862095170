import "./Header.scss";
import Icon from "@/components/UI/Icon/Icon";
import Button from "@/components/UI/Button/Button";
import { $t } from "@/i18n/i18n";
import { useContext, useState, useMemo, useEffect } from "react";
import { ScreenScrollContext } from "@/components/ScreenScroll/ScreenScroll";
import { screens } from "@/views/Home/constants";
import cn from 'classnames'
import parse from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccount, useConnect } from 'wagmi'
import { useWallet } from "../../web3/useWallet";
import { useFarm } from "../../web3/contracts/useFarm";
import { useSequoiaStore } from "../../store/store";
const Header = () => {
  const location = useLocation()
  const { activeId, dispatchActive } = useContext(ScreenScrollContext)
  const showButtons = `${activeId !== screens.MAIN ? "-active" : ''}`
  const showTitle = `-show-${activeId}`
  const mintClass = location.pathname === '/mint' ? '-mint-page' : ''
  const mySpaceClass = location.pathname === '/my-space' ? '-myspace-page' : ''
  // const {  nftsData } = useFarm();
  const totalReward = useSequoiaStore((state) => state.seqBalance)
  // const [totalReward, setTotalReward] = useState(0);

  // useEffect(() => {

  //     const calculateTotalReward = () => {
  //         const total = nftsData.reduce((acc, nft) => acc + Number(nft.rewardPending), 0);
  //         setTotalReward(total);
  //     };

  //     calculateTotalReward();
  // }, [nftsData]);



  const { connectWallet, disconnectWallet, isConnected, address } = useWallet();

  const toggleWalletConnection = () => {
    if (isConnected) {
      disconnectWallet();
    } else {
      connectWallet();
    }
  };


  const [web3Account, setWeb3Account] = useState({
    id: null,
  })
  const authorisationHandler = () => {
    const account = { id: '0x34209572353697' }
    setWeb3Account(account)
  }
  const isAuthorised = useMemo(() => web3Account.id !== null, [web3Account])
  // 

  const navigate = useNavigate()
  const routeTo = (page = '') => {
    if (isAuthorised) {
      navigate(page)
      return
    }
    authorisationHandler()
  }

  const routeToMain = () => {
    if (location.pathname === '/') {
      dispatchActive(screens.MAIN)
      return
    }
    navigate('/')
  }
  const truncateString = (str) => {
    return `${str.substring(0, 4)}...${str.substring(str.length - 3, str.length)}`;
};
  return (
    <header className={cn("header", showButtons, showTitle, mintClass,mySpaceClass)}>
      <Icon
        className="header__logo"
        label="logo"
        onClick={routeToMain}
      />
      <div className="header__titles">
        <h2 className="title_features">{$t('pages.home.features.title')}</h2>
        <h2 className="title_game">{$t('pages.home.game.title')}</h2>
        <h2 className="title_generation">{$t('pages.home.generation.title')}</h2>
        <h2 className="title_genesis">{$t('pages.home.genesis.slide_1.title')}</h2>
        <h2 className="title_genesis2">{parse($t('pages.home.genesis.slide_2.title'))}</h2>
        <h2 className="title_park">{$t('pages.home.park.header.title')}</h2>
        <h2 className="title_tokenomics">{$t('pages.home.tokenomics.title')}</h2>
        <h2 className="title_team">{$t('pages.home.team.title')}</h2>
        <h2 className="title_faq">{$t('pages.home.faq.title')}</h2>
      </div>
      <div className="header__buttons">

        <Button label={isConnected ? "metamaskConnected" : "metamask"} onClick={toggleWalletConnection} totalbalance={(totalReward / (10 ** 18)).toFixed(1)}>{isConnected ? truncateString(address): $t('pages.mint.metamask')}</Button>
        {isConnected && <Button label="mint" onClick={() => navigate('mint')}>{$t('components.buttons.mint.text')}</Button>}
        {/* <Button label="discord" link={$t('components.buttons.discord.link')}>{$t('components.buttons.discord.text')}</Button> */}
        {isConnected && <Button label="login" disabled={isConnected ? false : true} onClick={() => navigate('my-space')}>{$t('components.buttons.login.text')}</Button>}
        {/* <Button label="burger"></Button> */}
        {/* <Button label="cross"  onClick={() => navigate('/')}></Button> */}
      </div>
    </header>
  );
};

export default Header;
