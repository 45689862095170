import './SwitchBox.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, useEffect, React } from 'react'

const MySpace = ({ ...props }) => {

    const [isChecked, setIsChecked] = useState(true)


    useEffect(() => {
        // console.log('nft Change')
        if (props.nftsData.length > 0) {

            if (props.nftsData.filter((nft) => nft.lastReward == 0).length > 0) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }

        }

    }, [props.nftsData])


    const enable = async () => {
        await props.enableFarming()
    }


    return (
        <div className={cn('switch', isChecked ? '-checked' : '')}>
            {/* <input type="checkbox" className='switch__input'  /> */}
            {/* <div className={cn('switch__body', !isChecked ? '-activeBlock' : '')}></div> */}
            <div className={`switch__text ${!isChecked ? 'activeFarming' : ''}`} onClick={enable}>
                <p >
                    {isChecked ? 'off' : 'on'}
                </p>

                {/* <p  > now off
                    {isChecked? 'off':'on'}
                </p> */}
            </div>
        </div>
    )
}

export default MySpace

// import './SwitchBox.scss'
// import cn from 'classnames'
// import { $t } from '@/i18n/i18n'
// import { useState, React } from 'react'

// const MySpace = () => {

//     const [isChecked, setIsChecked] = useState(false)

//     return (
//         <div className={cn('switch', isChecked ? '-checked' : '')}>
//             <input type="checkbox" className='switch__input' onClick={() => setIsChecked(!isChecked)} />
//             <div className={`switch__body`}></div>
//             <div className='switch__text'>
//                 <p className={cn('text__on', !isChecked ? '-active' : '')}>

//                     deposit
//                 </p>
//                 <p className={cn('text__off', isChecked ? '-active' : '')} >
//                     withdraw
//                 </p>
//             </div>
//         </div>
//     )
// }

// export default MySpace