import './Mint.scss'
import MintNews from './components/MintNews/MintNews'
import Button from '@/components/UI/Button/Button'
import { $t } from '@/i18n/i18n'
import { useRenderer } from '@/animator/js/react/hooks'
import Scene from './components/Scene/Scene'
import { useAccount, useConnect, useDisconnect } from 'wagmi'


import Icon from '../../components/UI/Icon/Icon'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWallet } from '../../web3/useWallet'
import { useTotalSupply, sequoiaMarketUSDXABI, useCountPurchasePrice, useMintStatus, erc20ABI, sequoiaMarketABI } from '../../web3/contracts/sequoiaMarketUSDX'
import { alphaGenerationABI } from '../../web3/contracts/alphaGeneration'
import { useWriteContract, useWaitForTransactionReceipt, useGasPrice, } from 'wagmi'
import { useBlockNumberHook } from '../../web3/contracts/useBlockNumber'
import { readContract, estimateGas, getGasPrice } from '@wagmi/core'
import { config } from '../../web3/config'
import { farmingNFTAbi } from '../../web3/contracts/farmingNFT'
// import { useTotalSupply } from '../../web3/contracts/sequoiaMarketUSDX';

const Mint = () => {
    useRenderer()


    const navigate = useNavigate()


    const { data: status, error: errorMintStatus } = useMintStatus();

    const [approving, setIsApproving] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const [mintStatus, setMintStatus] = useState(null);
    const [activeQuantity, setActiveQuantity] = useState(1);
    const [showToast, setShowToast] = useState(false);

    const {
        data: hash,
        isPending,
        writeContractAsync,
        error
    } = useWriteContract()

    const {
        data: hash2,
        isPending: isPending2,
        writeContractAsync: writeContractAsyncMintPresale,
        error: error2
    } = useWriteContract()

    const { isLoading: isConfirmingApprove, isSuccess: isConfirmedApprove } = useWaitForTransactionReceipt({
        hash2,
    })

    const { isLoading: isConfirmingMint, isSuccess: isConfirmedMint } = useWaitForTransactionReceipt({
        hash,
    })







    const getPrice = async () => {

        const result = await readContract(config, {
            address: '0x606ed78FB35523513DBB3Cfc209AF93d6126eF28',
            abi: sequoiaMarketABI,
            functionName: 'price',

        })
        return result

    };





    useEffect(() => {
        // setIsMinting(isPending)
        // setMintStatus(hash);
        console.log('hash', hash)
        console.log('isPending', isPending)

        console.log('error', error)

        if (error || error2) {
            setIsMinting(false);
            setMintStatus('error');
        }
    }, [isPending, error,])

    const handleMint2 = async () => {
        setIsMinting(true);
        if (status === 1) {
            const res = await getPrice(); // Получаем цену токена
            const gasPrice = await getGasPrice(config);

            // eslint-disable-next-line no-undef
            const msgValue = BigInt(res) * BigInt(activeQuantity); // Рассчитываем сумму
            // eslint-disable-next-line no-undef
            const activeQuantity1 = BigInt(activeQuantity)

            const gasEstimate = await estimateGas(config, {
                address: '0x606ed78FB35523513DBB3Cfc209AF93d6126eF28', // Адрес контракта
                abi: sequoiaMarketABI, // ABI контракта
                functionName: 'mintPresale',
                args: [
                    activeQuantity1, // Количество токенов
                    '0x5d60e81BE40F0D46ebd21c00d8c26fE73AB408BA', // Адрес токена оплаты
                    [
                        "0x5d1d0ca1465953698c38ec979c75a0adeb637d34cc627020496994687ae1aa0b",
                        "0x66821c03487d25cecf158001d591ed9f450c9c0662fa8ea3083d0ab6da97859e",
                        "0x961c1aefa0c7c03cabc9fea215021f58dcadd0c63bc9be4a012b1b7b6f74df21",
                        "0xcf8b1c349e6d5abac18a14f66105f72fd332fce5bf345570933521c224d4358e",
                        "0xdd1be342eed73f239b1ac157f497608907dbce1afb251433eb0edf810402bcff"
                    ] // Merkle Proof
                ],
                value: msgValue,
            });
            // eslint-disable-next-line no-undef
            const gasEstimateNew = gasEstimate * BigInt(10)

            await writeContractAsyncMintPresale({
                address: '0x606ed78FB35523513DBB3Cfc209AF93d6126eF28', // Адрес контракта
                abi: sequoiaMarketABI, // ABI контракта
                functionName: 'mintPresale',
                args: [
                    activeQuantity1, // Количество токенов
                    '0x5d60e81BE40F0D46ebd21c00d8c26fE73AB408BA', // Адрес токена оплаты
                    [
                        "0x5d1d0ca1465953698c38ec979c75a0adeb637d34cc627020496994687ae1aa0b",
                        "0x66821c03487d25cecf158001d591ed9f450c9c0662fa8ea3083d0ab6da97859e",
                        "0x961c1aefa0c7c03cabc9fea215021f58dcadd0c63bc9be4a012b1b7b6f74df21",
                        "0xcf8b1c349e6d5abac18a14f66105f72fd332fce5bf345570933521c224d4358e",
                        "0xdd1be342eed73f239b1ac157f497608907dbce1afb251433eb0edf810402bcff"
                    ] // Merkle Proof
                ],
                value: msgValue,
                gas: gasEstimateNew,
                gasPrice: gasPrice,
                overrides: {
                    value: msgValue,
                    gasPrice: gasPrice,
                    gasLimit: gasEstimateNew,
                },

            });

            // await mintPresaleTx.wait()
        } else {
            try {
                const res = await getPrice(); // Получаем цену токена
                const gasPrice = await getGasPrice(config);

                // eslint-disable-next-line no-undef
                const msgValue = BigInt(res) * BigInt(activeQuantity); // Рассчитываем сумму
                // eslint-disable-next-line no-undef
                const activeQuantity1 = BigInt(activeQuantity)

                const gasEstimate = await estimateGas(config, {
                    address: '0x606ed78FB35523513DBB3Cfc209AF93d6126eF28',
                    abi: sequoiaMarketABI,
                    functionName: 'mint',
                    args: [activeQuantity1],
                    value: msgValue,
                });

                console.log('gasEstimate', gasEstimate)
                // eslint-disable-next-line no-undef
                const gasEstimateNew = gasEstimate * BigInt(10)

                await writeContractAsync({
                    address: '0x606ed78FB35523513DBB3Cfc209AF93d6126eF28',
                    abi: sequoiaMarketABI,
                    functionName: 'mint',
                    args: [activeQuantity1],
                    value: msgValue,
                    gas: gasEstimateNew,
                    gasPrice: gasPrice,
                    overrides: {
                        value: msgValue,
                        gasPrice: gasPrice,
                        gasLimit: gasEstimateNew,
                    },
                });
            } catch (error) {
                console.error("Error minting:", error);
                if (error.data && error.data.message) {
                    console.error("Error message:", error.data.message);
                }
            }
        }
    }



    useEffect(() => {
        if (!error && hash && isConfirmedMint) {
            setIsMinting(false);
            setMintStatus('success');

        }
    }, [error2, hash2, isConfirmedMint])


    const { connectWallet, disconnectWallet, isConnected, address } = useWallet();



    useEffect(() => {
        let toastTimeout;
        if (isConnected) {
            setShowToast(true);
            toastTimeout = setTimeout(() => {
                setShowToast(false);
            }, 2000);
        }

        return () => {
            clearTimeout(toastTimeout);
        };
    }, [isConnected])

    useEffect(() => {
        console.log('status', status)
    }, [status])


    const { currentBlockNumber, isError } = useBlockNumberHook();


    useEffect(() => {
        console.log('currentBlockNumber ', currentBlockNumber)
    }, [currentBlockNumber])


    return (
        <div id="mint" className='mint'>
            <h1>Be a part of Sequoia Family
                Mint our first 1,000 Alpha NFTs</h1>
            <MintNews />
            {!isConnected ? <Button label="metamask" onClick={connectWallet}>{isConnected ? address : $t('pages.mint.metamask')}</Button> :
                <>
                    <div className='MintBlock'>
                        <div className='MintBlockImg'>
                            <img src={require('./assets/mintPageImg.png')} />
                        </div>
                        <div className='MintBlockInfo'>
                            <div className='MintBlockInfo_item'>
                                <p>Quantity</p>
                                <div className='MintBlockInfo_Quantity'>
                                    <div className={activeQuantity == 1 ? `MintBlockInfo_itemQuantity activeitemQuantity` : 'MintBlockInfo_itemQuantity'} onClick={() => { setActiveQuantity(1) }}>
                                        1
                                    </div>
                                    <div className={activeQuantity == 2 ? `MintBlockInfo_itemQuantity activeitemQuantity` : 'MintBlockInfo_itemQuantity'} onClick={() => { setActiveQuantity(2) }}>
                                        2
                                    </div>
                                    <div className={activeQuantity == 3 ? `MintBlockInfo_itemQuantity activeitemQuantity` : 'MintBlockInfo_itemQuantity'} onClick={() => { setActiveQuantity(3) }}>
                                        3
                                    </div>
                                </div>

                            </div>
                            <div className='MintBlockInfo_item'>
                                <p>Total Price</p>
                                <p>{150 * activeQuantity} MATIC</p>
                            </div>
                            <div className='MintBlockInfo_button' onClick={handleMint2}>
                                <Icon label={'mint'} />
                                <p>Mint Now </p>
                            </div>
                            <p>Status: {status === 1 ? "PreSale" : status === 2 ? "Sale" : "Disabled"}</p>

                        </div>
                    </div>

                    <div className='mint__subinfo' onClick={disconnectWallet}>Disconnect Metamask</div>
                </>}

            <div className={showToast ? `mint_successfullyConnectedWalletWrapper` : 'mint_successfullyConnectedWalletWrapper mint_successfullyConnectedWalletWrapperHide'} >
                <div className='mint_successfullyConnectedWallet' >
                    <div className='mint_successfullyConnectedWallet_icon'>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.4307 13.4406C20.7383 17.68 17.1449 20.8381 12.8517 20.9805C8.55854 21.1229 4.76383 18.2097 3.79206 14.0255C2.82029 9.84134 4.94271 5.55397 8.85921 3.78966C12.7757 2.02535 17.3931 3.27655 19.8831 6.77683L12.573 14.0579L9.48576 10.9686" stroke="#1DAF7E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <p>Metamask Wallet Connected Succesully</p>
                </div>
            </div>
            {/* isMinting  */}
            {isMinting && (
                <div className="mint__popupWrapper">

                    <div className="mint__popup">
                        <span class="loader"></span>
                        {/* <p>Minting NFT...</p> */}

                        <div className="mint__popupTransactionItemBlock">
                            <p className="mint__popupTransactionItem">{approving ? 'Approving...' : 'Minting...'}</p>
                            {/* <div className="mint__popupTransactionItemLoaderWrapper"><span class="loaderMini"></span></div> */}
                        </div>
                    </div>


                </div>
            )}
            {mintStatus === 'success' && (
                <div className="mint__popupWrapper">
                    <div className="mint__popup">
                        <div className="mint__popupImg">
                            <img src={require('./assets/like.png')} />
                        </div>
                        <p>Your NFT Succesfully minted.<br />Find it in My Space</p>
                        <div className="mint__popupButton" onClick={() => navigate('/my-space')}>
                            OK
                        </div>
                    </div>
                </div>
            )}
            {mintStatus === 'error' && (
                <div className="mint__popupWrapper">
                    <div className="mint__popup">
                        <div className="mint__popupImg">
                            <img src={require('./assets/error.png')} />
                        </div>
                        <p>Something went wrong. Try again.</p>
                        <div className="mint__popupButton" onClick={() => { setMintStatus(null) }}>
                            OK
                        </div>
                    </div>
                </div>
            )}

            <div className='mint__background'>
                <Scene />
                <div className={isConnected ? `blur` : `blur blurHide`}></div>
            </div>
        </div>
    )
}

export default Mint