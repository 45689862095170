import './TreeNFTs.scss'
import cn from 'classnames'
import { $t } from '@/i18n/i18n'
import { useState, React } from 'react'
import Card from './Card/Card'
import LegendaryImage from './Card/assets/legendary 1 7.png'
import EpicImage from './Card/assets/Epic.png'
import RareImage from './Card/assets/Rare.png'
import CommonImage from './Card/assets/common 3 2.png'
import { rarity } from '../constants'
import EmptyCard from './EmptyCard/EmptyCard'
import { cardsMock } from './TreeNTSMock/cardsMock'
import { map } from 'lodash'

const TreeNFTs = ({ ...props }) => {


    // const cardsLength = cardsMock.length
    // console.log(cardsLength)

    const cardimgarray = [2, 1, 4, 3, 1, 2, 4, 3, 1, 2, 4, 2, 3, 4, 1, 4, 1, 2, 3, 2, 1, 4, 3, 2, 1, 3, 4, 2, 3, 1, 2, 4, 1, 3, 2, 4, 1, 2, 3, 1, 4, 2, 3, 1, 2, 4, 3, 1, 2, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4, 2, 1, 3, 4]


    return (
        <div className='tree-nfts'>
            <div className='tree-nfts__title'>
                <p>
                    {$t('pages.myspace.title')}
                </p>
                <div className='tree-nfts__title__image'>
                    <span>{`${props.nftsData.length ? props.nftsData.length : '-'}`}</span>
                </div>
            </div>
            <div className='tree-nfts__cards'>
                {/* {props.nftsData.map((_, i) => (
                    <Card tag={`${_.id}`} rewardPending={`${_.rewardPending}`} rewardPerBlock={`${_.rewardPerBlock}`} image={_.image} rarity={_[3]} delayedImage={_.image}></Card>
                ))} */}
                {props.nftsData.length > 0 ? props.nftsData.map((_, i) => (

                    <Card key={`${i}cards${i}`} tag={`${_.id}`} cardNumber={cardimgarray[i]} rewardPending={`${_.rewardPending}`} rewardPerBlock={`${_.rewardPerBlock}`} image={_.image} rarity={_[3]} delayedImage={_.image}></Card>
                )) : <>
                    <EmptyCard />
                    <EmptyCard />
                    <EmptyCard />
                    <EmptyCard />
                    <EmptyCard />

                </>}
                {/* <EmptyCard />
                <EmptyCard />
                <EmptyCard /> */}

            </div>
        </div>
    )
}

export default TreeNFTs